import * as React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../styles/global.css'
import Seo from '../../components/Seo'

// Context lets us pass a value deep into the component tree
// without explicitly threading it through every component.
// Create a context for the current theme (with "light" as the default).
export const LanguageContext = React.createContext('English');
export const ThemeContext = React.createContext('Light');

// markup
const TermsPage = () => {
  return (
    <ThemeContext.Provider value="Dark">
      <LanguageContext.Provider value={"English"}>
        <main style={{ backgroundColor: "#333" }}>
          <Seo
            title='Matthe815.dev'
          />

          <p style={{ color: "white", display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", alignItems: "center", flexWrap: "wrap", textAlign: "center" }}>
            Terms and Conditions for Starie
            Last Updated: 11/29/2024
            <br/>
            Welcome to Starie! By using or interacting with [Your Bot’s Name] (the “Bot”), you agree to comply with and be bound by the following Terms and Conditions (the “Terms”). If you do not agree to these Terms, please do not use or access the Bot. These Terms apply to all users, including server administrators and regular users.
            <br/><br />
            1. Acceptance of Terms By using Starie, you acknowledge that you have read, understood, and agree to be bound by these Terms, as well as any additional guidelines or policies that may apply to specific features or services provided by the Bot.
            <br/><br />
            2. User Responsibilities
            <br/>
            You agree not to use the Bot for any unlawful, harmful, or malicious purposes, including but not limited to, harassment, spam, or distributing malicious content.
            You must not attempt to reverse-engineer, decompile, or tamper with the Bot’s code or underlying technology.
            You agree to use the Bot in compliance with Discord’s Terms of Service and Community Guidelines.
            <br/><br />
            3. Permissions and Access
            <br/>
            By adding Starie to your Discord server, you grant the Bot the necessary permissions to function properly (e.g., sending messages, managing roles, etc.).
            You are responsible for ensuring that the permissions you grant the Bot are appropriate for your server’s needs.
            <br/><br />
            4. Data Collection and Privacy
            <br/>
            Starie may collect and store data as necessary to provide its services. This may include user interactions, server information, and logs.
            We do not share user data with third parties unless required by law or necessary for the operation of the Bot.
            Your use of the Bot implies consent to our <a href="https://matthe815.dev/starie/privacy">Privacy Policy</a>, which outlines how we collect, use, and protect your information.
            <br/><br />
            5. Changes to the Bot
            <br/>
            We may periodically update the Bot to add features, improve performance, or fix bugs.
            We reserve the right to modify, suspend, or discontinue the Bot or any of its services without prior notice.
            <br/><br />
            6. Limitation of Liability
            <br/>
            The Bot is provided on an “as-is” basis. We make no warranties or representations regarding its availability, performance, or accuracy.
            We are not liable for any indirect, incidental, or consequential damages that may arise from the use of the Bot, including but not limited to data loss or service disruptions.
            <br/><br />
            7. Indemnification You agree to indemnify and hold Starie and its creators harmless from any claims, damages, or losses that arise from your use of the Bot or any violation of these Terms.
            <br/>
            8. Termination
            We reserve the right to terminate or suspend your access to the Bot at our discretion, without prior notice, if we believe you have violated these Terms or engaged in abusive behavior.
            You may terminate your use of the Bot at any time by removing it from your server.
            <br/><br />
            9. Governing Law These Terms are governed by the laws of The United States of America, without regard to its conflict of laws principles.
            <br/><br />
            10. Acknowledgement By using Starie, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.
          </p>

        </main>
      </LanguageContext.Provider>
    </ThemeContext.Provider>
  )
}

export default TermsPage
